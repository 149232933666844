import React from 'react';

const AnidaPageData = [
    {
      id: 1,
      imageSrc: './images/AnidaPage/anidaimamovicsalon.webp',
      altText: 'Vlasnica Salona Anida Imamovic',
      title: 'Anida Imamovic',
      description: 'Kao mala sam znala da ću se baviti ovim poslom. Jedva sam cekala da mogu da ostvarim svoj san i da imam svoj salon. Kada sam zavrsila srednju skolu upisala sam frizersku akademiju i od tada sam u ovom poslu. Volim svoj posao i trudim se da svaki dan budem bolja i da svojim klijentima pruzim naj',
    },
    // {
    //   id: 2,
    //   imageSrc: './images/AnidaPage/anidaimamovicsalon.webp',
    //   altText: 'Vlasnica Salona Anida Imamovic',
    //   title: 'Anida Imamovic',
    //   description: 'Kao mala sam znala da ću se baviti ovim poslom. Jedva sam cekala da mogu da ostvarim svoj san i da imam svoj salon. Kada sam zavrsila srednju skolu upisala sam frizersku akademiju i od tada sam u ovom poslu. Volim svoj posao i trudim se da svaki dan budem bolja i da svojim klijentima pruzim naj',
    // },
  ];


  export default AnidaPageData;
  