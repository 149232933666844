const GalleryPageData = [
  {
    id: 1,
    img: "./images/GalleryPage/kosa1.jpg",
  },
  {
    id: 2,
    img: "./images/GalleryPage/kosa2.jpg",
  },
  {
    id: 3,
    img: "./images/GalleryPage/kosa3.jpg",
  },
  {
    id: 4,
    img: "./images/GalleryPage/kosa4.jpg",
  },
  {
    id: 5,
    img: "./images/GalleryPage/kosa5.jpg",
  },
  {
    id: 6,
    img: "./images/GalleryPage/kosa6.jpg",
  },
  {
    id: 7,
    img: "./images/GalleryPage/kosa7.jpg",
  },
  {
    id: 8,
    img: "./images/GalleryPage/kosa8.jpg",
  },
  {
    id: 9,
    img: "./images/GalleryPage/kosa9.jpg",
  },
  {
    id: 10,
    img: "./images/GalleryPage/kosa10.jpg",
  },
  {
    id: 11,
    img: "./images/GalleryPage/kosa11.jpg",
  },
  {
    id: 12,
    img: "./images/GalleryPage/kosa12.jpg",
  },
  {
    id: 13,
    img: "./images/GalleryPage/kosa13.jpg",
  },
  {
    id: 14,
    img: "./images/GalleryPage/kosa14.jpeg",
  },
  {
    id: 15,
    img: "./images/GalleryPage/kosa15.jpeg",
  },
  {
    id: 16,
    img: "./images/GalleryPage/kosa16.jpeg",
  },
  {
    id: 17,
    img: "./images/GalleryPage/kosa17.jpg",
  },
  {
    id: 18,
    img: "./images/GalleryPage/kosa18.jpg",
  },
  
];

export default GalleryPageData;
